import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../common/Buttons/button/Button'
import CreateOrderForm from './СreateOrderForm/CreateOrderForm'
import { Modal } from '../common/modal/Modal'
import { Confirmation } from '../common/confirmation/Confirmation'
import { CustomPrompt } from '../common/customPrompt/CustomPrompt'
import { OrdersList } from './OrdersList/OrdersList'

import { appActions } from '../../../redux/appReducer'
import { actionsOrder, createOrderInServer, getOrderCode } from '../../../redux/ordersReducer'
import { getWorkpointsFromServer } from '../../../redux/workpointsReducer'

import s from './orders.module.scss'

export const Orders = () => {
  const orders = useSelector(state => state?.orders?.ordersCreate?.results)
  const linkFile = useSelector(state => state?.orders?.link)
  const isDownload = useSelector(state => state?.orders?.isDownload)
  const responsibleUserId = useSelector(state => state?.auth?.id)
  const userId = useSelector(state => state?.auth?.id)
  const workpoints = useSelector(state => state?.points?.points)
  const workpointId = useSelector(state => state?.auth?.workpoint)
  const {isFormFromHall} = useSelector(state => state.orders)
  const [order, setOrder] = useState({})
  const [filterOrders, setFilterOrders] = useState([])
  const [modalClass, setModalClass] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [isSave, setIsSave] = useState(false)
  const dispatch = useDispatch()
  const workpointName = workpoints.filter(item => item.id === workpointId)[0]?.prefix

  // вычисляем выбранную торговую точку и возвращаем код договора, если есть ручной ввод названия договора
  const codeWorkpoint = () => workpointId === 6 ? 'В зал' : ''

  useEffect(() => {
    dispatch(getWorkpointsFromServer())
  }, [dispatch])

  // сортировка заказов по номерам/дате создания
  useEffect(() => {
    if (orders) {
      setFilterOrders(orders
        .sort((a, b) => {
          const firstNum = +a.code.split('-')[1]
          const secondNum = +b.code.split('-')[1]

          return firstNum < secondNum ? 1 : firstNum > secondNum ? -1 : 0
        })
        .sort((a, b) => {
          const dateFirst = a.created_datetime.split('.').reverse().join(', ')
          const dateSecond = b.created_datetime.split('.').reverse().join(', ')

          a = new Date(dateFirst)
          b = new Date(dateSecond)
          return a > b ? -1 : a < b ? 1 : 0
        })
      )
    }
  }, [orders])

  // открытие модального окна создания заказа
  const onCreate = (isFromHall) => {
    dispatch(actionsOrder.setFormFromHall(isFromHall))
    dispatch(getOrderCode())
    dispatch(appActions.showModal(true))
    setModalClass(true)
    dispatch(appActions.showPrompt(null))
  }

  // закрытие модального окна создания заказа при клике на крестик, с очисткой полей
  const closeForm = (e) => {
    e.preventDefault()
    setModalClass(false)
    dispatch(appActions.showModal(false))
  }

  // eslint-disable-next-line
  const handleCancel = useCallback(() => setOpenConfirm(false), [])

  // отправка на сервер
  const submitData = useCallback(() => {
    if (!isFormFromHall) {
      if (!isSave) {
        order.order.status = 2
        order.order.prepayment_date = new Date().toLocaleDateString()
      } else {
        order.order.status = 1
      }
    }
    if (isFormFromHall) {
      order.order.prepayment_date = new Date().toLocaleDateString()
    }

    dispatch(createOrderInServer(order))
    setModalClass(false)
    handleCancel()
    setIsSave(false)

    // eslint-disable-next-line
  }, [order, dispatch, handleCancel])

  // Функция подготовки данных к отправке на сервер
  const onSubmit = formData => {
    const orderData = JSON.parse(JSON.stringify(formData))
    const data = {
      'order': {
        'payment_method': (orderData.order.payment_type) ? +orderData.order.payment_type : null,
        'payment_type': (orderData.order.payment_type) ? +orderData.order.payment_type : null,
        'prepayment': (orderData.order.prepayment) ? +orderData.order.prepayment.toString().split(' ').join('') : 0,
        'payment': (orderData.order.payment) ? +orderData.order.payment.toString().split(' ').join('') : 0,
        'extrapayment': (orderData.order.percent) ? +orderData.order.percent : 0,
        'price': (orderData.total) ? +orderData.total.toString().split(' ').join('') : 0,
        'customer_full_name': orderData.order.customer_full_name || '',
        'customer_phone_number': orderData.order.customer_phone_number || '',
        'customer_address': orderData.order.customer_address || '',
        'customer_district': orderData.order.customer_district || '',
        'delivery_date_from': orderData.order.delivery_date_from || '',
        'delivery_date_to': orderData.order.delivery_date_to || orderData.order.delivery_date_from,
        'production_date_from': orderData.order.production_date_from || '',
        'production_date_to': orderData.order.production_date_to || orderData.order.production_date_from,
        'responsible_user': responsibleUserId,
        'code': (orderData.code) ? `${codeWorkpoint()}-${orderData.code}` : orderData.code,
        'in_hall': isFormFromHall
      },
      'items': []
    }

    // собираем инфу о товаре
    for (let i = 0; i < orderData.goods.length; i++) {
      const items = orderData.goods[i].orderInfo[0].items
      const good = orderData.goods[i].orderInfo[0].items[0].good[0]
      const material = orderData.goods[i].orderInfo[0]

      let goodData = {
        'discount': good.discount || 0,
        'quantity': good.quantity || 1,
        'product': good.product || '',
        'price': (good.price) ? +good.price.toString().split(' ').join('') : 0,
        'cost': (good.cost) ? +good.cost.toString().split(' ').join('') : 0,
        'sum': (good.sum) ? +good.sum.toString().split(' ').join('') : 0,
        'schema': orderData.goods[i].orderInfo[0].scheme || '',
        'leg': orderData.goods[i].orderInfo[0].legsId || '',
        'molding': orderData.goods[i].orderInfo[0].moldingId || '',
        'note_tk1': orderData.goods[i].orderInfo[0].tk1 || '',
        'note_tk2': orderData.goods[i].orderInfo[0].tk2 || '',
        'note': orderData.goods[i].orderInfo[0].commentary || '',
        'materials_type': orderData.goods[i].orderInfo[0].materials_type || null,
        'materials': [
          {
            'material': material['material1'],
            'name': material['name1'] || '',
            'provider': material['provider1'] || '',
            'price': (material['materialPrice1']) ? +material['materialPrice1'].toString().split(' ').join('') : 0
          },
          {
            'material': material['material2'],
            'name': material['name2'] || '',
            'provider': material['provider2'] || '',
            'price': (material['materialPrice2']) ? +material['materialPrice2'].toString().split(' ').join('') : 0
          },
          {
            'material': material['material3'],
            'name': material['name3'] || '',
            'provider': material['provider3'] || '',
            'price': (material['materialPrice3']) ? +material['materialPrice3'].toString().split(' ').join('') : 0
          },
        ],
        'options': []
      }
      if (isFormFromHall) {
        goodData = {
          ...goodData,
          'id': good.id
        }
      }

      for (let k = 1; k < items.length; k++) {
        let options = {
          'quantity': items[k]?.good[0]?.quantity,
          'name': items[k]?.good[0]?.product,
          'price': (items[k]?.good[0]?.price) ? items[k].good[0].price.toString().split(' ').join('') : 0,
          'cost': (items[k]?.good[0]?.cost) ? items[k].good[0].cost.toString().split(' ').join('') : 0,
          'sum': (items[k]?.good[0]?.sum) ? items[k].good[0].sum.toString().split(' ').join('') : 0,
          'discount': items[k]?.good[0]?.discount
        }
        goodData.options.push(options)
      }
      data.items.push(goodData)
    }

    setOpenConfirm(true)
    setOrder(data)
  }

// закрыть модалку с информацией о загрузке
  const closeDownload = () => {
    dispatch(actionsOrder.setDocumentLink('', false))
  }

  return (
    <>
      {
        isDownload &&
        <div className={s.sendForm}>
          <p onClick={closeDownload} className={s.closeDownload}>x</p>
          <h3 className={s.sendFormTitle}>
            Загрузка договора...
          </h3>
          <p className={s.sendFormText}>Если документ не открылся нажмите
            <a href={linkFile} download target={'_blank'} rel="noreferrer">посмотреть</a></p>
        </div>
      }

      {!modalClass && <CustomPrompt/>}
      <Modal styleProps={{width: '1000px'}}
             modalClass={modalClass}
             setModalClass={setModalClass}
             openConfirm={openConfirm}>
        <CreateOrderForm
          onSubmit={onSubmit}
          setIsSave={setIsSave}
          closeForm={closeForm}
        />
      </Modal>
      {
        openConfirm &&
        <Confirmation
          text="Подтвердите действие"
          submitData={submitData}
          handleCancel={handleCancel}
        />
      }
      <h1>Заказы</h1>
      <section className="order-creation">
        <div className="container">
          <div className={s.buttonsOrderCreate}>
            <Button
              title="Продажа на заказ"
              icon={true}
              onClick={() => onCreate(false)}
              className={s.buttonCreate}
            />
            <Button
              title="Продажа из зала"
              icon={true}
              onClick={() => onCreate(true)}
              className={s.buttonCreate}
            />
          </div>

          <OrdersList
            filterOrders={filterOrders}
            userId={userId}
            workpointName={workpointName}
            modalClass={modalClass}
          />
        </div>
      </section>
    </>
  )
}